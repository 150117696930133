/* eslint-disable arrow-body-style */
// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@sync/core";

// ----------------------------------------------------------------------

type Option = {
  value: string;
  label: string;
};

type IProps = {
  name: string;
  options: Option[];
  defaultValue?: any;
  isRequired?: boolean;
};

type Props = IProps & RadioGroupProps;

export default function RHFRadioGroup({
  name,
  options,
  defaultValue,
  isRequired,
  children,
  ...other
}: Props) {
  const { control } = useFormContext();

  const generateRadioOptions = () => {
    return options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        label={option.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required: isRequired }}
      render={({ field, fieldState: { error } }) => (
        //prop error from fieldState estava sempre válida, então o o check do error foi feito "manual"
        <FormControl
          sx={{ m: 3 }}
          variant="standard"
          // error={isRequired && field.value == ""}
          error={!!error}
        >
          <RadioGroup {...field} {...other}>
            {generateRadioOptions()}
          </RadioGroup>
          {/* {isRequired && field.value == "" && (
              <FormHelperText>Campo obrigatório</FormHelperText>
            )} */}
          {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
