import Color from "color";

import { ButtonProps, ContainerFilterProps, IconProps } from "./props";
import { styled } from "@sync/core";
import { Icon } from "@iconify/react";

export const Container = styled("div")`
  width: 100%;
  position: relative;
  min-width: 140px;

  @media (max-width: 500px) {
    width: 100%;
    min-width: unset;
    max-width: 100%;
  }
`;

export const SelectedOption = styled("button")<ButtonProps>`
  width: 100%;
  max-width: ${({ variant }) => variant == "small" && "250px"};
  height: ${({ variant }) => (variant == "small" ? "42px" : "56px")};
  min-width: ${({ variant }) => (variant == "small" ? "200px" : "unset")};
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid #ccc;
  cursor: pointer;
  transition: border-color 0.2s;

  &::placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.palette.text.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & p {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & i {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const Options = styled("div")<ContainerFilterProps>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 16px;
  margin-top: 10px;
  flex-direction: column;
  gap: 16px;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 8px;
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid
    ${({ theme }) => Color(theme.palette.text.secondary).alpha(0.15).toString()};
`;

export const Option = styled("div")`
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;

  transition: color 0.2s;
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const Chevron = styled(Icon)<IconProps>`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.palette.text.primary};
  transform: ${({ open }) => (open ? "rotateX(180deg)" : "rotateX(0deg)")};
  transition: all 0.3s;
`;
