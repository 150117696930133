import { Autocomplete, TextField, TextFieldProps } from "@sync/core";
import { Controller, useFormContext } from "react-hook-form";
import CustomPopper from "./popper/index.popper";
import { SelectProps } from "../basic-select-input/props";

type Props = {
  name: string;
  label: string;
  options: any[];
  defaultValue?: any;
  isRequired?: boolean;
  getOptionLabel: (option: any) => string;
  isOptionEqualToValue: (option: any, value: any) => boolean;
  customOnChange?: (newValue: SelectProps) => void;
  removeEndAdornment?: boolean;
  id?: string;
};

type AutocompleteProps = Props & TextFieldProps;

export default function RHFAutocomplete({
  name,
  label,
  options,
  defaultValue,
  isRequired,
  getOptionLabel,
  isOptionEqualToValue,
  customOnChange,
  removeEndAdornment = false,
  id,
  ...other
}: AutocompleteProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required: isRequired }}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, item) => {
            field.onChange(item);
            if (customOnChange) {
              customOnChange(item);
            }
          }}
          id={id ?? "make"}
          value={field.value}
          PopperComponent={CustomPopper}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <TextField
              {...params}
              {...other}
              id={id ?? "make"}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                endAdornment: removeEndAdornment ? (
                  <div />
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
              helperText={(() => {
                if (error != undefined) {
                  return error.type == "required"
                    ? "Campo obrigatório"
                    : error.message;
                }
              })()}
              name={name}
              label={label}
              margin="none"
            />
          )}
        />
      )}
    />
  );
}
