export const styles = {
  popper: {
    width: "calc(100%)",
    maxWidth: "300px",
    zIndex: 99999,
    "&::WebkitScrollbar": {
      width: "0.4em",
    },
  },
};
