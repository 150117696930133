// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { FormControlLabel, FormControlLabelProps, Switch } from "@sync/core";

// ----------------------------------------------------------------------

interface Props extends Omit<FormControlLabelProps, "control"> {
  name: string;
}

export default function RHFSwitch({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      defaultValue={false}
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Switch {...field} checked={field.value} />}
          {...other}
        />
      )}
    />
  );
}
