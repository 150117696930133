import { BasicSelectInputProps } from "./props";
import { useEffect, useRef, useState } from "react";
import { Chevron, Container, Option, Options, SelectedOption } from "./styles";
import { InputSkeleton } from "./skeleton";

export default function BasicSelectInput<T>({
  onClose,
  options,
  setValue,
  value,
  loading,
  variant = "normal",
  placeholder,
}: BasicSelectInputProps<T>) {
  const dropdownElement = useRef<HTMLDivElement | null>(null);
  const buttonElement = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  function handleToggleFilterDropdown() {
    setOpen((state) => !state);
  }

  function handleSelectFilter(orderType: any) {
    setValue(orderType);
    handleToggleFilterDropdown();
    onClose?.();
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        dropdownElement.current &&
        !dropdownElement.current.contains(event.target) &&
        open
      ) {
        if (
          buttonElement.current &&
          !buttonElement?.current?.contains(event.target)
        ) {
          handleToggleFilterDropdown();
          onClose?.();
        }
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdownElement, buttonElement, open]);

  if (loading) {
    return (
      <Container>
        <InputSkeleton />
      </Container>
    );
  }

  const selectedOption = options.find((option) => option?.value === value);

  return (
    <Container>
      <SelectedOption
        variant={variant}
        onClick={handleToggleFilterDropdown}
        ref={buttonElement}
        type="button"
      >
        <p>{selectedOption?.label || placeholder}</p>
        <Chevron icon="ion:chevron-down-outline" open={open} />
      </SelectedOption>
      <Options style={{ zIndex: 10000 }} visible={open} ref={dropdownElement}>
        {options.map((option) => (
          <Option
            key={option.value}
            onClick={() => handleSelectFilter(option.value)}
          >
            {option.label}
          </Option>
        ))}
      </Options>
    </Container>
  );
}
