// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@sync/core";
import { ChangeEvent } from "react";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  defaultValue?: any;
  isRequired?: boolean;
  customOnChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  children?: any;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  name,
  defaultValue,
  isRequired,
  customOnChange,
  children,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required: isRequired }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === "number" && field.value === 0
              ? ""
              : field.value || ""
          }
          error={!!error}
          //to do: obter mensagem de erro "oficial"
          helperText={(() => {
            if (error != undefined) {
              return error.type == "required"
                ? "Campo obrigatório"
                : error.message;
            }
          })()}
          sx={{
            position: "relative",
            zIndex: 3000,
          }}
          {...other}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            field.onChange(e.target.value);
            if (customOnChange) {
              customOnChange(e);
            }
          }}
        >
          {children}
        </TextField>
      )}
    />
  );
}
